<template>
  <product-with-file type="file" :media-types="mediaTypes" />
</template>

<script>
import AvailableFileMainMediaTypes from '@/data/file_main_media_types';
import ProductWithFile from '../ProductWithFile.vue';

export default {
  name: 'FileEditor',
  components: {
    ProductWithFile,
  },
  data() {
    return {
      mediaTypes: AvailableFileMainMediaTypes(key => this.$t(key)),
    };
  },
};
</script>
