import { lookup as mimeByExtension } from 'mime-types';

export default translate => [
  {
    id: 'file',
    title: translate('sparkmembers.contents.views.editor.components.file.components.main-media.types.file.title'),

    icon: 'book-alt',
    active: false,
    supportedFiles: [mimeByExtension('.pdf'), mimeByExtension('.xls'), mimeByExtension('.xlsx'), '.csv'],
    subTypes: [],
  },
];
