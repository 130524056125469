import { createLocalVue, shallowMount } from '@vue/test-utils';
import FileProduct from './';
import Vuex from 'vuex';

jest.mock('@/router');
jest.mock('@/store');
jest.mock('@/services/analytics');
jest.mock('@/shared/helpers/toast');

const localVue = createLocalVue();
localVue.use(Vuex);

const stubs = {
  HsGroup: true,
  HsTextEditor: true,
  HsForm: true,
  HsIcon: true,
  HsInput: true,
  HsFormInvalidFeedback: true,
  HsButton: {
    template: '<div class="hs-button-stub"></div>',
    props: ['disabled'],
  },
  LessonReleaseLimitationCard: true,
};

function mountWrapper(localVue, stubs, store, propsData) {
  return shallowMount(FileProduct, {
    localVue,
    stubs,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $router: {
        push: jest.fn(),
      },
      $route: {
        params: {
          contentId: 10,
        },
      },
      $store: new Vuex.Store(store),
    },
    propsData,
  });
}

describe('File Product Content', () => {
  it('should render', () => {
    const wrapper = mountWrapper(
      localVue,
      stubs,
      {},
      {
        type: 'file',
        mediaTypes: [
          {
            id: 'file',
            title: 'title',
            icon: 'book-alt',
            active: false,
            supportedFiles: [],
          },
        ],
      }
    );

    expect(wrapper.exists()).toBeTruthy();
  });
});
